const container = document.querySelector('#image-navigation');
const arrowLeft = document.querySelector('.image-nav-arrow-container .arrow');
const arrowRight = document.querySelector('.image-nav-arrow-container .arrow-right');
const items = document.querySelectorAll('#image-navigation .nav-item .text-box');

const bindEvents = () => {
  arrowLeft?.addEventListener('click', () => horizontalScroll('back'));
  arrowRight?.addEventListener('click', horizontalScroll);
  window.addEventListener('resize', renderArrows);
  container.addEventListener('scroll', renderArrows);
  container.addEventListener('mousedown', mouseDownHandler);
};

const mouseDownHandler = function (e) {
  pos = {
    // The current scroll
    left: container.scrollLeft,
    // Get the current mouse position
    x: e.clientX,
    y: e.clientY,
  };

  document.addEventListener('mousemove', mouseMoveHandler);
  document.addEventListener('mouseup', mouseUpHandler);
};

const mouseMoveHandler = function (e) {
  container.querySelectorAll('a').forEach((link) => {
    link.style.pointerEvents = 'none';
  });
  // How far the mouse has been moved
  const dx = e.clientX - pos.x;

  // Scroll the element
  container.scrollLeft = pos.left - dx;
};

const mouseUpHandler = function () {
  container.querySelectorAll('a').forEach((link) => {
    link.style.pointerEvents = 'auto';
  });
  document.removeEventListener('mousemove', mouseMoveHandler);
  document.removeEventListener('mouseup', mouseUpHandler);

  container.style.cursor = 'grab';
  container.style.removeProperty('user-select');
};

const horizontalScroll = (direction) => {
  scrollAmount = 0;
  var slideTimer = setInterval(function () {
    if (direction === 'back') {
      container.scrollLeft -= 50;
    } else {
      container.scrollLeft += 50;
    }

    setArrowsFromScrollStates();
    scrollAmount += 50;
    if (scrollAmount >= 500) {
      window.clearInterval(slideTimer);
    }
  }, 27);
};

const renderArrows = () => {
  if (container.scrollWidth > container.clientWidth) {
    setArrowsFromScrollStates();
  } else {
    arrowLeft?.classList.remove('d-lg-flex');
    arrowRight?.classList.remove('d-lg-flex');
  }
};

const setArrowsFromScrollStates = () => {
  if (Math.round(container.scrollWidth) === Math.round(container.clientWidth + container.scrollLeft)) {
    arrowRight?.classList.remove('d-lg-flex');
  } else {
    arrowRight?.classList.add('d-lg-flex');
  }
  if (container.scrollLeft === 0) {
    arrowLeft?.classList.remove('d-lg-flex');
  } else {
    arrowLeft?.classList.add('d-lg-flex');
  }
};

const scrollToSelectedCategory = () => {
  if (container.scrollWidth > container.clientWidth) {
    items.forEach((item, i) => {
      if (item.classList.contains('active')) {
        let scrollPos = item.offsetLeft;
        if (window.innerWidth < 992) scrollPos -= 20;
        else scrollPos -= container.clientWidth * 0.03;
        container.scrollLeft += scrollPos;
      }
    });
  }
};

document.addEventListener('DOMContentLoaded', function () {
  bindEvents();
  renderArrows();
  scrollToSelectedCategory();
});
